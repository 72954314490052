const config = {
    "OPERATION_NAME":"Link Engie",
    "API_URL":"https://2024.link.engie.api.b-fly.com",
    "TINY_MCE_API_KEY":"1527x3ef5ajbywfdm25kadjmxzahw16uvrzdkfdn0sydl15y",
    "AUTH0_DOMAIN": "butterfly-henner.eu.auth0.com",
    "AUTH0_CLIENT_ID": "senuxLNAlCHnKOTEmlcNI34rv6NAK3Gf",
    "AUTH0_AUDIENCE": "https://2024.link.engie.api.b-fly.com/",
};

export default config;
